<template>
  <form>
    <div class="form-group mb-4">
      <label>Google Ad Account <span class="text-danger">*</span></label>
      <select :class="['form-control', {'is-invalid': errorFor('ad_account_id')}]"
              v-model="form.ad_account_id"
              :disabled="loading">
        <option value="">Select Ad Account</option>
        <option :value="account.id"
                v-for="account in adAccounts"
                :key="'ad_account_'+account.id">{{ account.name }}</option>
      </select>
      <v-errors :errors="errorFor('ad_account_id')" />
    </div>

    <div class="form-group mb-4">
      <label>Amazon Advertising Account <span class="text-danger">*</span></label>

      <AmazonProfileSelect :amazonAdAccounts="amazonAdAccounts"
                           @changed="changeAmazonProfile"
                           :disabled="loading" />
<!--      <select :class="['form-control', {'is-invalid': errorFor('amazon_profile')}]"
              v-model="form.amazon_profile"
              :disabled="loading">
        <option value="">Select Amazon Profile</option>
        <option :value="account.profileId"
                v-for="account in amazonAdAccounts"
                :key="'amazon_account_'+account.profileId">{{ account.accountInfo.name }} ({{ account.currencyCode }})</option>
      </select>
      <v-errors :errors="errorFor('amazon_profile')" />-->
    </div>

    <div class="form-group mb-4">
      <label>Campaign Name <span class="text-danger">*</span></label>

      <input :class="['form-control', {'is-invalid': errorFor('name')}]"
             :disabled="loading"
             v-model="form.name"
             type="text">
      <v-errors :errors="errorFor('name')" />
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Campaign Period <span class="text-danger">*</span></label>

          <div class="input-group">
            <input type="text" class="form-control" id="campaignPeriod">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">
                <i class="flaticon-calendar"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 ms-auto">
        <div class="form-group">
          <label>&nbsp;</label>
          <div class="custom-control custom-checkbox checkbox-primary">
            <input type="checkbox"
                   class="custom-control-input"
                   value="true"
                   id="basicChk2"
                   v-model="form.no_end_date"
                   @change="changeContinue($event)">
            <label class="custom-control-label" for="basicChk2">No end date</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Daily Budget <span class="text-danger">*</span></label>

          <input :class="['form-control', {'is-invalid': errorFor('budget')}]"
                 :disabled="loading"
                 v-model="form.budget"
                 type="text">
          <v-errors :errors="errorFor('budget')" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Maximum CPC</label>

          <input :class="['form-control', {'is-invalid': errorFor('max_cpc')}]"
                 :disabled="loading"
                 v-model="form.max_cpc"
                 placeholder="(Optional)"
                 type="text">
          <v-errors :errors="errorFor('max_cpc')" />
        </div>
      </div>
    </div>
    <p class="text-black font-bold text-md mb-0 mt-4">You can change the Daily Budget and Maximum CPC later</p>
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import AmazonProfileSelect from "@/components/AmazonProfileSelect.vue";

export default {
  props: ['formData', 'errors'],
  components: {AmazonProfileSelect},
  data() {
    return {
      adAccounts: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
  },
  created() {
    this.axios.get('/ad-accounts/google')
      .then(response => {
        this.adAccounts = response.data.data;
      })
  },
  mounted() {
    this.dateRangePickerInit(false);
  },
  methods: {
    changeAmazonProfile(profileId) {
      this.form.amazon_profile = profileId;
    },
    changeContinue(event) {
      this.dateRangePickerInit(event.target.checked);
    },
    dateRangePickerInit(single) {
      let self = this;

      if (single) {
        window.$("#campaignPeriod").daterangepicker({
            singleDatePicker: true,
            minDate: window.moment().startOf("hour"),
            startDate: window.moment().startOf("hour"),
            locale: {
              format: 'M/DD/YYYY'
            },
          },
          function (t) {
            self.form.start_time = t.format();
          }
        )
      } else {
        window.$("#campaignPeriod").daterangepicker({
            startDate: window.moment().startOf("hour"),
            minDate: window.moment().startOf("hour"),
            endDate: window. moment().startOf("hour").add(32, "hour"),
            locale: {
              format: 'M/DD/YYYY'
            },
          },
          function (t, a) {
            self.form.start_time = t.format('YYYYMMDD');
            self.form.end_time = a.format('YYYYMMDD');
          }
        )
      }
    },
  }
}
</script>